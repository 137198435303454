import Aos from 'aos'
import { useEffect, useState } from 'react'

import TitleSong from './../../assets//sounds/title.mp3'
import Select from './../../assets//sounds/menu.wav'
import Start from './../../assets//sounds/start.wav'
import Logo from './../../assets/images/logo.png'
import { Navigate, redirect } from 'react-router-dom'

export default function (props) {
    const [step, setStep] = useState(0)
    const [redirect, setRedirect] = useState(false)
    const [redirectPage, setRedirectPage] = useState('')
    const [sound, setSound] = useState(false)

    const [start] = useState(new Audio(Start))
    const [titleSound] = useState(new Audio(TitleSong))

    const [animationStartGame, setAnimationStartGame] = useState(false)
    const [animationRanking, setAnimationRanking] = useState(false)


    useEffect(() => {
        window.scrollTo(0, 0)

        Aos.init({
            duration: 1000
        })


    }, [])


    const goToOptions = async () => {
        setStep(1)

        setTimeout(() => {
            setStep(2)
        }, 1000)
    }

    useEffect(() => {
        if (step) {
            start.play()
        }
    }, [step])

    useEffect(() => {
        if (sound) {
            titleSound.play()
        } else {
            titleSound.pause()
        }
    }, [sound])


    const playMenuOpt = () => {
        new Audio(Select).play()
    }


    const startGame = () => {
        new Audio(Start).play()

        setAnimationStartGame(true)

        setTimeout(() => {
            setAnimationStartGame(false)

            setRedirect(true)
            // titleSound.pause()
            setRedirectPage('/game')

        }, 1000)
    }

    const startRanking = () => {
        new Audio(Start).play()

        setAnimationRanking(true)

        setTimeout(() => {
            setAnimationRanking(false)

            setRedirect(true)
            // titleSound.pause()
            setRedirectPage('/ranking')

        }, 1000)
    }


    return (
        <>
            {redirect ? <Navigate replace to={redirectPage} /> : false}
            <div className="game-container">
                <section className="splash-screen load">
                    <div className='game-logo-container'>
                        <div data-aos="fade-down" data-aos-easing="linear" data-aos-duration="500">
                            {/* <img src={Logo} /> */}

                            {/* <div class="title">
                            <h1 class="title-top"><span class="big">M</span>emo</h1>
                            <h1 class="title-bottom"><span class="big">G</span>ame</h1>
                        </div> */}

                            <span className="yellow-with-darkyellow game-title"> Memo Game</span>
                        </div>
                    </div>

                    {step === 0 || step === 1
                        ? <>
                            <div className="buttons grey-with-red" onClick={() => step === 0 || step === 1 ? goToOptions() : false}>
                                <a className={`
                            purple-with-blue cursor-pointer 
                            ${step === 0 ? 'blink' : ''}
                            ${step === 1 ? 'fastBlink' : ''}
                        `}>Touch to start</a>
                                <a className={`cursor-pointer 
                    ${step === 0 ? 'blink' : ''}
                    ${step === 1 ? 'fastBlink' : ''}
                     `}>One Player</a>
                            </div>
                        </>
                        : <></>
                    }

                    {step === 2
                        ? <>
                            <div className="buttons grey-with-red">

                                <div className='option-container'>

                                    <a
                                        className={`
                                    option-item cursor-pointer 
                                    ${animationStartGame ? 'fastBlink' : ''}
                                    `}
                                        onMouseEnter={() => playMenuOpt()}
                                        onClick={() => startGame()}
                                    >
                                        Iniciar
                                    </a>

                                    <a
                                        className={`
                                    option-item cursor-pointer 
                                    ${animationRanking ? 'fastBlink' : ''}
                                    `}
                                        onMouseEnter={() => playMenuOpt()}
                                        onClick={() => startRanking()}
                                    >
                                        Ranking
                                    </a>
                                </div>

                            </div>
                        </>
                        : <></>
                    }


                    <div className="bottom">
                        <div className="sound grey-with-red" onClick={() => setSound(!sound)}>Music <span class="yellow-with-darkyellow">{sound ? 'ON' : 'OFF'}</span></div>

                        <a href="#" className='main-bottom'>
                            <span className="yellow">©</span>
                            <span className="yellow-with-darkyellow"> Atelie de Propaganda</span>
                        </a>

                        <div className="credit grey-with-red">Credit <span class="yellow-with-darkyellow">?</span></div>
                    </div>
                </section>
            </div>
        </>
    )
}

