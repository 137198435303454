import Aos from 'aos'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Keyboard from 'react-simple-keyboard'
import Select from './../../assets//sounds/menu.wav'
import Error from './../../assets//sounds/error.wav'
import Game from './game'

export default function (props) {
    let navigate = useNavigate();

    const [layoutName, setLayoutName] = useState('default')
    const [inputName, setINputName] = useState('')
    const [startGame, setStartGame] = useState(false)
    const [newPlayer, setNewPlayer] = useState()
    const [players, setPlayers] = useState()

    useEffect(() => {
        window.scrollTo(0, 0)

        Aos.init({
            duration: 1000
        })

        var players = localStorage.getItem("memogame-players")

        if (players) {
            players = JSON.parse(players)
            setPlayers(players)
        }

    }, [])

    const changeKeyboard = (e) => {
        if (e === "{shift}" || e === "{lock}") {
            new Audio(Select).play()
            handleShift()
        }

        if (e === '{enter}') {
            if (!inputName || inputName.length < 3) {
                new Audio(Error).play()
            } else {

                var players = localStorage.getItem("memogame-players")

                if (players) {
                    players = JSON.parse(players)
                }else{
                    players = []
                }

                const found = players.find(obj => {
                    return obj.name === inputName;
                })

                if (found) {
                    new Audio(Error).play()
                } else {
                    var new_player = []

                    if (Array.isArray(players)) {
                        players.map((row, index) => {
                            new_player.push(row)
                        })
                    }

                    var length = 10;
                    var result = '';
                    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                    var charactersLength = characters.length;
                    for (var i = 0; i < length; i++) {
                        result += characters.charAt(Math.floor(Math.random() * charactersLength));
                    }

                    new_player.push({
                        name: inputName,
                        player_id: result
                    })

                    setNewPlayer({
                        name: inputName,
                        player_id: result
                    })

                    localStorage.setItem("memogame-players", JSON.stringify(new_player))

                    setStartGame(true)
                }


            }
        } else {
            new Audio(Select).play()
        }
    }

    const handleShift = () => {
        setLayoutName(
            layoutName === "default" ? "shift" : "default"
        )
    }

    return (
        <>
            <div className="game-container">
                <section className="splash-screen load">
                    <div className='game-logo-setName'>
                        <div data-aos="fade-down" data-aos-easing="linear" data-aos-duration="500">
                            <span className="yellow-with-darkyellow game-setName"> Memo Game</span>
                        </div>
                    </div>

                    {startGame && inputName
                        ? <Game name={inputName} player={newPlayer} />
                        : <>
                            {players
                                ? <div className='players-frame'>
                                    <div
                                        onClick={() => navigate('/')}
                                        className='yellow-with-darkyellow back-btn'
                                    >
                                        Voltar
                                    </div>
                                    <span className="yellow-with-darkyellow purple-with-blue "> SELECIONE UM JOGADOR</span> <br/>
                                    <div className='players-content'>
                                        <div className='player-row'>
                                            {players.map((row, index) => {
                                                return (
                                                    <div className='grey-with-red player-item cursor-pointer' key={index} onClick={() => [
                                                        setINputName(row.name),
                                                        setStartGame(true),
                                                        setPlayers({
                                                            name: row.name,
                                                            player_id: row.player_id
                                                        })
                                                    ]}>

                                                        {row.name}

                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                                : <></>
                            }

                            <div className='keyboard-frame'>
                                <div className='keyboard-container'>

                                    <div className='input-name-container'>
                                        <div className='input-label'>Nome do jogador</div>
                                        <div className='input-name'>{inputName}</div>
                                    </div>

                                    <Keyboard
                                        layoutName={layoutName}
                                        layout={{
                                            default: [
                                                "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
                                                "{tab} q w e r t y u i o p [ ] \\",
                                                "{lock} a s d f g h j k l ; ' {enter}",
                                                "{shift} z x c v b n m , . / {shift}",
                                                ".com @ {space}"
                                            ],


                                            shift: [
                                                "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
                                                "{tab} Q W E R T Y U I O P { } |",
                                                '{lock} A S D F G H J K L : " {enter}',
                                                "{shift} Z X C V B N M < > ? {shift}",
                                                ".com @ {space}"
                                            ]
                                        }}
                                        display={{
                                            "{bksp}": "⌫",
                                            "{escape}": "esc ⎋",
                                            "{tab}": "tab ⇥",
                                            "{backspace}": "backspace ⌫",
                                            "{enter}": "enter ↵",
                                            "{lock}": " ⇪",
                                            "{shift}": "shift ⇧",
                                            "{shiftright}": "shift ⇧",
                                            "{controlleft}": "ctrl ⌃",
                                            "{controlright}": "ctrl ⌃",
                                            "{altleft}": "alt ⌥",
                                            "{altright}": "alt ⌥",
                                            "{metaleft}": "cmd ⌘",
                                            "{metaright}": "cmd ⌘",
                                            "{space}": "&nbsp;"
                                        }}
                                        onChange={e => setINputName(e)}
                                        onKeyPress={e => changeKeyboard(e)}
                                        buttonTheme={[
                                            {
                                                class: "hg-red",
                                                buttons: "{enter}"
                                            }
                                        ]}
                                        theme={"hg-theme-default hg-layout-default myTheme"}
                                    />
                                </div>
                            </div>
                        </>
                    }


                </section>

            </div>





        </>
    )
}