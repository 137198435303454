import Aos from "aos"
import { useEffect, useState } from "react"
import { Navigate } from "react-router-dom"

export default function (props) {
    const [redirect, setRedirect] = useState(false)
    const [redirectPage, setRedirectPage] = useState('')

    const [ranking, setRanking] = useState([])


    const goToHome = () => {
        setRedirect(true)
        // titleSound.pause()
        setRedirectPage('/')

    }

    useEffect(() => {
        window.scrollTo(0, 0)

        Aos.init({
            duration: 1000
        })

        var ranking = localStorage.getItem("memogame-ranking")

        if (ranking) {
            ranking = JSON.parse(ranking)

            const new_ranking = []

            ranking.map((row, index) => {
                var time = row.time
                var hms = time;
                var target = new Date("1970-01-01T" + hms).getTime() / 1000;

                new_ranking.push({
                    name: row.name,
                    time: row.time,
                    timestamp: target,
                    moves: row.moves
                })
            })

            const setPerson = new Set();

            new_ranking.sort((a, b) => {
                return a.timestamp - b.timestamp
            })

            const filterPerson = new_ranking.filter((person) => {
                const duplicatedPerson = setPerson.has(person.name);
                setPerson.add(person.name);
                return !duplicatedPerson;
            });

            console.log(filterPerson)

            setRanking(filterPerson)
        }


    }, [])

    return (
        <>
            {redirect ? <Navigate replace to={redirectPage} /> : false}

            <div className="game-container">
                <section className="splash-screen load">
                    <div className='game-logo-setName'>
                        <div data-aos="fade-down" data-aos-easing="linear" data-aos-duration="500">
                            <span className="yellow-with-darkyellow game-setName"> Memo Game</span>
                        </div>
                    </div>

                    <div className="purple-with-blue ranking-title">
                        Ranking
                    </div>

                    <div className="yellow-with-darkyellow back-btn " onClick={() => goToHome()}>
                        VOLTAR
                    </div>

                    <div className="ranking-container">
                        <div className="ranking-header" style={{ width: '900px' }}>
                            <div className="ranking-header-item yellow-with-darkyellow" style={{ width: '40%' }}>NAME</div>
                            <div className="ranking-header-item yellow-with-darkyellow center-content" style={{ width: '30%' }}><center>MOVIMENTOS</center></div>
                            <div className="ranking-header-item yellow-with-darkyellow center-content" style={{ width: '30%' }}><center>TEMPO</center></div>
                        </div>
                    </div>


                    {ranking.map((row, index) => {
                        if (index < 10) {
                            return (
                                <div className="ranking-container" key={index}>
                                    <div className="ranking-row" style={{ width: '900px' }}>
                                        <div className="ranking-row-item grey-with-red" style={{ width: '40%' }}>{index + 1} {row.name}</div>
                                        <div className="ranking-row-item grey-with-red center-content" style={{ width: '30%' }}><center>{row.moves}</center></div>
                                        <div className="ranking-row-item grey-with-red center-content ft-10" style={{ width: '30%' }}><center>{row.time}</center></div>
                                    </div>
                                </div>
                            )
                        }
                    })}


                </section>
            </div>



        </>
    )
}