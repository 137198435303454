import {
	createBrowserRouter,
	RouterProvider,
} from "react-router-dom"

import "./assets/styles/app.css"
import 'simple-keyboard/build/css/index.css'


import Home from "./pages/home"
import Game from "./pages/game"
import Ranking from "./pages/ranking"


const router = createBrowserRouter([
	{
		path: "/",
		element: <Home />,
	},

	{
		path: "/game",
		element: <Game />,
	},

	{
		path: "/ranking",
		element: <Ranking />,
	},
])

function App() {
	return (
		<div>


			<RouterProvider router={router} />

		</div>
	)
}

export default App
