import React from "react"
import classnames from "classnames"
import redHatCard from "../../assets/images/card.jpg"

const Card = ({ onClick, card, index, isInactive, isFlipped, isDisabled }) => {
    const handleClick = () => {
        !isFlipped && !isDisabled && onClick(index)
    }

    return (
        <div
            className={classnames("card-redhat", {
                "is-flipped": isFlipped,
                "is-inactive": isInactive
            })}
            onClick={isInactive ? null : handleClick}
            id={'card_' + index}
        >
            <div className="card-face card-font-face">
                <img src={redHatCard} alt="red-hat" />
            </div>

            <div className="card-face card-back-face">
                <img src={card.image} alt="red-hat" />
            </div>
        </div>
    )
}

export default Card