import { useEffect, useRef, useState } from "react"

import Card from "./card"
import Game from './../../assets/sounds/umba.mp3'
import { Navigate } from "react-router-dom";


const uniqueCardsArray = [
    {
        type: "1",
        image: require('../../assets/images/card_1.jpg')
    },
    {
        type: "2",
        image: require('../../assets/images/card_2.jpg')
    },
    {
        type: "3",
        image: require('../../assets/images/card_3.jpg')
    },
    {
        type: "4",
        image: require('../../assets/images/card_4.jpg')
    },
    {
        type: "5",
        image: require('../../assets/images/card_5.jpg')
    },
    {
        type: "6",
        image: require('../../assets/images/card_6.jpg')
    }
]



function shuffleCards(array) {
    const length = array.length;

    for (let i = length; i > 0; i--) {
        const randomIndex = Math.floor(Math.random() * i);
        const currentIndex = i - 1;
        const temp = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temp;
    }

    return array
}


var timeBegan = null
    , timeStopped = null
    , stoppedDuration = 0
    , started = null;

export default function (props) {
    const [cards, setCards] = useState(
        shuffleCards.bind(null, uniqueCardsArray.concat(uniqueCardsArray))
    )
    const [shouldDisableAllCards, setShouldDisableAllCards] = useState(false)
    const [openCards, setOpenCards] = useState([])
    const [clearedCards, setClearedCards] = useState({})
    const [moves, setMoves] = useState(0)
    const [points, setPoints] = useState(0)

    const [modalEndGame, setModalEndGame] = useState(false)
    const [timeGame, setTimeGame] = useState('')

    const [countdown, setCountdown] = useState(3)
    const [playing, setPlaying] = useState(false)

    const [redirect, setRedirect] = useState(false)
    const [redirectPage, setRedirectPage] = useState('')


    const timeout = useRef(null)

    useEffect(() => {
        if (!playing) {
            const timer = setInterval(function () {
                if (countdown !== 0) {
                    setCountdown(countdown - 1)
                }
            }, 1000)


            if (countdown === 0) {
                startGame()
            }

            return () => {
                clearInterval(timer)
            }
        }
    }, [countdown])



    function startClock() {
        if (timeBegan === null) {
            timeBegan = new Date();
        }

        if (timeStopped !== null) {
            stoppedDuration += (new Date() - timeStopped);
        }

        started = setInterval(clockRunning, 10);
    }

    function stopClock() {
        timeStopped = new Date();
        clearInterval(started);
    }

    function resetClock() {
        clearInterval(started);
        stoppedDuration = 0;
        timeBegan = null;
        timeStopped = null;
        document.getElementById("display-area").innerHTML = "00:00:00.000";
    }

    function clockRunning() {
        var currentTime = new Date()
            , timeElapsed = new Date(currentTime - timeBegan - stoppedDuration)
            , hour = timeElapsed.getUTCHours()
            , min = timeElapsed.getUTCMinutes()
            , sec = timeElapsed.getUTCSeconds()
            , ms = timeElapsed.getUTCMilliseconds();

        document.getElementById("display-area").innerHTML =
            (hour > 9 ? hour : "0" + hour) + ":" +
            (min > 9 ? min : "0" + min) + ":" +
            (sec > 9 ? sec : "0" + sec) + "." +
            (ms > 99 ? ms : ms > 9 ? "0" + ms : "00" + ms);
    };

    const checkIsFlipped = (index) => {
        return openCards.includes(index)
    }

    const checkIsInactive = (card) => {
        return Boolean(clearedCards[card.type])
    }

    const showCards = () => {
        for (var i = 0; i < 12; i++) {
            document.getElementById('block').classList.remove('none')

            document.getElementById('card_' + i).classList.add('is-flipped')
        }

        setTimeout(() => {
            for (var i = 0; i < 12; i++) {
                document.getElementById('card_' + i).classList.remove('is-flipped')
            }
        }, 3000)

        setTimeout(() => {

            document.getElementById('block').classList.add('none')
            // startClock()
        }, 3000)

    }

    const disable = () => {
        setShouldDisableAllCards(true)
    }

    const enable = () => {
        setShouldDisableAllCards(false)
    }

    const startGame = () => {
        setPlaying(true)
        showCards()
        resetClock()

        setTimeout(() => {
            startClock()

            // new Audio(Game).play()

        }, 3000)
    }

    useEffect(() => {
        let timeout = null

        if (openCards.length === 2) {
            timeout = setTimeout(evaluate, 300)
        }

        return () => {
            clearTimeout(timeout)
        }
    }, [openCards])

    const checkPoints = () => {
        if (points === 5) {
            stopClock()

            rankGenerate()

            // setInterval(() => {
            //     goToRanking()
            // }, 3000)

            setModalEndGame(true)
        }
    }

    const evaluate = () => {
        const [first, second] = openCards

        enable()

        if (cards[first].type === cards[second].type) {
            setPoints(points + 1)

            setTimeout(() => {
                checkPoints()
            }, 100)

            setClearedCards((prev) => ({ ...prev, [cards[first].type]: true }))
            setOpenCards([])
            return
        }

        timeout.current = setTimeout(() => {
            setOpenCards([])
        }, 500)
    }

    const handleCardClick = (index) => {
        if (openCards.length === 1) {
            setOpenCards((prev) => [...prev, index])
            setMoves((moves) => moves + 1)
            disable()
        } else {
            clearTimeout(timeout.current)
            setOpenCards([index])
        }
    }

    const rankGenerate = () => {

        setTimeGame(document.getElementById('display-area').innerHTML)

        var new_user = {
            name: props.name,
            moves: moves,
            time: document.getElementById('display-area').innerHTML
        }

        var ranking = localStorage.getItem("memogame-ranking")

        if (ranking) {
            ranking = JSON.parse(ranking)
        }

        var new_ranking = []

        if (Array.isArray(ranking)) {
            ranking.map((row, index) => {
                new_ranking.push(row)
            })
        }

        new_ranking.push(new_user)

        localStorage.setItem("memogame-ranking", JSON.stringify(new_ranking))
    }

    const goToRanking = () => {
        setRedirect(true)
        // titleSound.pause()
        setRedirectPage('/ranking')
    }


    const restartGame = () => {
        setModalEndGame(false)
        resetClock()
        
        setOpenCards([])
        setCards(shuffleCards.bind(null, uniqueCardsArray.concat(uniqueCardsArray)))
        setMoves(0)
        setPoints(0)
        setCountdown(3)
        setPlaying(false)
        setClearedCards({})
        showCards()
        setTimeout(() => {
            startClock()
        }, 3000)
    }

    return (
        <>
            {redirect ? <Navigate replace to={redirectPage} /> : false}


            <div id="block" className='none' />

            {countdown !== 0
                ? <div className="countdown">
                    <div className="countdown-container grey-with-red">
                        {countdown}
                    </div>
                </div>
                : <></>
            }

            <output id="display-area" className="clock-container purple-with-blue">00:00:00.000</output>

            <div className="player-container">
                <span className="grey-with-red">Player:</span>
                <span className="yellow-with-darkyellow">{props.name}</span>
            </div>


            <div className="moves-container">
                <span className="grey-with-red">Movimentos:</span>
                <span className="yellow-with-darkyellow">{moves}</span>
            </div>

            <div className="cards-screen">
                <div className='cards-container'>
                    {cards.map((card, index) => {
                        return (
                            <Card
                                key={index}
                                card={card}
                                index={index}
                                isDisabled={shouldDisableAllCards}
                                isInactive={checkIsInactive(card)}
                                isFlipped={checkIsFlipped(index)}
                                onClick={handleCardClick}
                            />
                        )
                    })}
                </div>
            </div>



            {
                modalEndGame
                    ? <>

                        <div className="modal-end-game">
                            <div className="modal-end-game-container">

                                <div className="modal-end-game-title yellow-with-darkyellow">
                                    Completed
                                </div>



                                <div className="modal-end-game-score purple-with-blue" >
                                    <div>Tempo</div>
                                    <div>{timeGame}</div>
                                </div>


                                <div className="modal-end-game-options">
                                    <div className="modal-end-game-restart grey-with-red" onClick={() => restartGame()}>
                                       Restart
                                    </div>

                                    <div className="modal-end-game-ranking grey-with-red" onClick={() => goToRanking()}>
                                        Ranking
                                    </div>
                                </div>


                            </div>
                        </div>
                    </>
                    : <></>
            }
        </>
    )
}